.data-container {
  display: flex;
  flex-direction: column;
}

.data-row {
  display: flex;
  align-items: center;
  padding: 0px;
  border-bottom: 1px solid #e4e4e4;
  cursor: pointer;
  transition: all 0.1s ease-in;
}

.data-row:hover {
  background-color: #f6f6f6;
}

.data-left {
  font-family: "Century Gothic";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  padding-left: 5px;
  width: 150px;
}

.arrow-icon.red {
  color: #ff0000;
  /* Red color */
}

.data-bp {
  text-align: center;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 4px;
}

.data-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.data-item {
  width: 45px;
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}

.bp-reading {
  font-size: 12px;
  color: #555;
  margin-top: 5px;
}

.data-item-name {
  font-weight: 400;
}

.user-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 29px;
  height: 21px;
  border-radius: 123px;
  color: white;
}

.vertical-divider {
  border-left: 1px solid #e0e0e0;
  height: 24px;
  margin: 0 10px;
}

.bp-HL {
  display: flex;
  justify-content: center;
  color: black;
  text-align: right;
  font-family: "Century Gothic";
  font-size: 14px;
  line-height: normal;
  font-weight: bolder;
}

.bp-indicator {
  position: absolute;
  top: 7px;
  left: -14px;
}
