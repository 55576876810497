.Bp {
  height: 40px;
  width: 55px;
  position: fixed;
  z-index: 999;
  bottom: -10px;
  right: -20px;
  background-color: #1f8cff;
  border-radius: 55px;
  color: white;
  font-size: 14px;
  display: flex;
  opacity: 0;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  transition: 0.5s cubic-bezier(1, -0.19, 1, 1);
  box-shadow: rgba(0, 0, 0, 0.062) 0px 0px 5px, rgba(6, 6, 6, 0.4) 2px 4px 10px;
}

.Bp.expanded {
  opacity: 1;
  border-radius: 10px;
  height: 260px;
  width: 400px;
  display: inline-flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  position: fixed;
  background: white;
  right: 40px;
  bottom: 20px;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px;
  cursor: auto;
}


.notification {
  position: absolute;
  top: 3px;
  left: 5px;
  font-size: 16px;
  font-weight: 700;
}

.close-btn {
  position: absolute;
  top: 4px;
  right: 0px;
  background: transparent;
  border: none;
  color: black;
  font-size: 14px;
  cursor: pointer;
  z-index: 1000;

}

.bp-list {
  height: 100%;
  width: 100%;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  transition: 0.4s;
  animation: blur normal 0.6s;
}

@keyframes blur {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.heading-notification {
  position: relative;
  height: 15%;
  width: 100%;
  animation: blur normal 0.6s;
  background: #EDF5FD;
  border-radius: 5px;
}


.bp-title {
  position: absolute;
  top: 8px;
  animation: blur normal 0.6s;
}

.indecator-wrapper {
  position: absolute;
  top: 5px;
  right: 0;
  animation: blur normal 0.6s;
}

.blinking {
  animation: 1.3s blink ease-in infinite;
}

@keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

/* @-moz-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
} */